<template>
  <v-app id="app">
    <div class="wrapper">
      <app-bar />
      <dex-info-bar />
      <div class="wrapper__inner">
        <router-view />
      </div>
      <app-footer />
    </div>
    <components v-for="item in components" :key="item.id" :is="item.component" />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import AppBar from "@/components/common/AppBar"
import AppFooter from '@/components/common/AppFooter';
import DexInfoBar from '@/components/common/DexInfoBar';

export default {
  name: 'App',
  data() {
    return {
      layout: 'none-layout',
      countryCode: ''
    }
  },
  components: {
    AppBar,
    AppFooter,
    DexInfoBar
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    ...mapState({
      components: state => state.components.items,
    }),
    userBalance() {
      return this.$store.state.user.userBalance;
    },
    userPoint() {
      return this.$store.state.user.userPoint;
    },
  },
  watch: {
    walletAddress() {
      if (this.walletAddress) {
        this.init()
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('checkUserInfo');
    this.init()
  },
  methods: {
    async init() {
      await this.$store.dispatch('checkChain');
      this.$store.dispatch('getBalanceOf', this.walletAddress);
      this.$store.dispatch('getUserPoint', this.walletAddress);
      this.$store.dispatch('getAllowence', this.walletAddress);
    }
  }
};
</script>