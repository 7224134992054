<template>
  <div class="connect-wallet-mobile">
    <div v-if="walletAddress">
      <v-btn v-if="!isAllowChain" @click="openNetworksModal" class="WrongNetwork"></v-btn>
      <div v-else class="UserIn" @click="openWalletModal">
        <v-img :src="makeBlockie(walletAddress)"></v-img>
      </div>
    </div>
    <v-btn v-else class="Connect" @click="openSelectWallet"></v-btn>
  </div>
</template>

<script>
import define from '@/define';
import config from '@/config';
import makeBlockie from 'ethereum-blockies-base64';
import { concatAddress } from '@/utils/concat';
import { provider, face } from '@/utils/facewalletLogin';
export default {
  name: "ConnectWalletMobile",
  data() {
    return {
      isFaceChainId: undefined
    }
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    chainId() {
      return this.$store.state.chain.chain.id;
    },
    isAllowChain() {
      const walletType = this.$store.state.chain.chain.type ?? define.FACE_WALLET;
      if (walletType == define.METAMASK) {
        if (window.ethereum && window.ethereum?.chainId) {
          const chainId = window.ethereum.chainId;
          if (config.allowChain.includes(chainId)) {
            return true;
          }
        }
      } else if (walletType == define.FACE_WALLET) {
        if (provider !== 'undefined' && this.isFaceChainId) {
          if (config.allowChain.includes(this.isFaceChainId)) {
            return true;
          }
        }
      }
      return this.$store.getters.isAllowChain;
    },
  },
  watch: {
  },
  async mounted() {
    await this.faceChainId();
  },
  methods: {
    makeBlockie,
    concatAddress,
    openSelectWallet() {
      this.$store.dispatch('openSelectWalletModal');
    },
    openWalletModal() {
      this.$store.dispatch('openWalletModal');
    },
    openNetworksModal() {
      this.$store.dispatch('openNetworksModal');
    },
    async faceChainId() {
      await face.getChainId()
      .then(res => {
        let num = res-0
        num = "0x" + num.toString(16)
        this.isFaceChainId = num;
      })
    },
  }
}
</script>

<style>
</style>