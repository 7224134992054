<template>
  <header class="header">
    <div class="header__screen">
      <div class="header__screen--info">
        <router-link to="/" class="logo"></router-link>
        <ul class="gnb">
          <li class="gnb__title" @mouseleave="closeGameTab">
            <v-btn @mouseover="openGameTab" :class="{ 'targetAcitve': gameTabs, 'isActive': $route.name === 'hyper' || $route.name === 'social' }" class="lnb-target gnb__title--link">GameZ</v-btn>
            <ul v-if="gameTabs" class="lnb-group">
              <li>
                <v-btn to="/social-gamez" :class="{ 'isActive': $route.name === 'social' }">Social Casual Zone</v-btn>
              </li>
              <li>
                <v-btn to="/hyper-gamez" :class="{ 'isActive': $route.name === 'hyper' }">Hyper Casual Zone</v-btn>
              </li>
            </ul>
          </li>
          <li class="gnb__title">
            <v-btn to="/rank" :class="{ 'isActive': $route.name === 'rank' }" class="gnb__title--link">Ranking</v-btn>
          </li>
          <li class="gnb__title">
            <v-btn to="/profile?t=profile" :class="{ 'isActive': $route.name === 'profile' }" class="gnb__title--link">Profile</v-btn>
          </li>
          <li class="gnb__title" @mouseleave="closeDocsTab">
            <v-btn @mouseover="openDocsTab" :class="{ 'targetAcitve': docsTabs }" class="lnb-target gnb__title--link">Docs</v-btn>
            <ul v-if="docsTabs" class="lnb-group">
              <li>
                <v-btn href="https://meversedex.gitbook.io/meverse-gamez/" target="_blank">GameZ Docs</v-btn>
              </li>
              <li>
                <v-btn href="https://meversedex.gitbook.io/docs/" target="_blank">Dex Docs</v-btn>
              </li>
              <li>
                <v-btn href="https://meversedex.gitbook.io/pocketbattlesnftwar/pocket-battles/introduction" target="_blank">Pocket Battles Docs</v-btn>
              </li>
              <li>
                <v-btn href="https://meversedex.gitbook.io/meverse-dev-docs/" target="_blank">Dev Docs</v-btn>
              </li>
            </ul>
          </li>
          <!-- <li class="gnb__title">
            <v-btn href="https://www.pocketbattlesnftwar.com/" target="_blank" class="gnb__title--link href">Pocket Battles: NFT War</v-btn>
          </li>
          <li class="gnb__title">
            <v-btn @click="openSnackBar" class="gnb__title--link href">Heroes NFT War</v-btn>
          </li> -->
        </ul>
      </div>
      <div class="header__screen--user">
        <div class="user-controller">
          <p @click="openConvertModal" class="buy-mev">Convert ZP</p>
          <connect-wallet />
        </div>
        <connect-wallet-mobile />
        <img @click="openMenu" class="menu-btn" src="@/assets/media/Icon/Menu.svg" alt="Menu 메뉴" />
      </div>
    </div>
    <div :class="{ 'isActive': menu }" @click="closeMenu" class="header__mask"></div>
    <div class="header__mobile" :class="{ 'toggleMenu': menu }">
      <div class="header__mobile--inner">
        <v-btn class="close-btn" @click="closeMenu"></v-btn>
        <ul class="m-gnb">
          <li class="m-gnb__title">
            <v-btn @click="activeGameTab" :class="{ 'targetAcitve': gameMobileTabs }" class="m-lnb-target m-gnb__title--link">GameZ</v-btn>
            <ul v-if="gameMobileTabs" class="m-lnb-group">
              <li><v-btn @click="closeMenu" to="/social-gamez" :class="{ 'isActive': $route.name === 'social' }">Social Casual Zone</v-btn></li>
              <li><v-btn @click="closeMenu" to="/hyper-gamez" :class="{ 'isActive': $route.name === 'hyper' }">Hyper Casual Zone</v-btn></li>
            </ul>
          </li>
          <li class="m-gnb__title">
            <v-btn @click="closeMenu" to="/rank" class="m-gnb__title--link">Ranking</v-btn>
          </li>
          <li class="m-gnb__title">
            <v-btn @click="closeMenu" to="/profile?t=profile" class="m-gnb__title--link">Profile</v-btn>
          </li>
          <li class="m-gnb__title">
            <v-btn @click="activeDoceTab" :class="{ 'targetAcitve': docsMobileTabs }" class="m-lnb-target m-gnb__title--link">Docs</v-btn>
            <ul v-if="docsMobileTabs" class="m-lnb-group">
              <li>
                <v-btn @click="closeMenu" href="https://meversedex.gitbook.io/meverse-gamez/" target="_blank">GameZ Docs</v-btn>
              </li>
              <li>
                <v-btn @click="closeMenu" href="https://meversedex.gitbook.io/docs/" target="_blank">Dex Docs</v-btn>
              </li>
              <li>
                <v-btn @click="closeMenu" href="https://meversedex.gitbook.io/pocketbattlesnftwar/pocket-battles/introduction" target="_blank">Pocket Battles Docs</v-btn>
              </li>
              <li>
                <v-btn @click="closeMenu" href="https://meversedex.gitbook.io/meverse-dev-docs/" target="_blank">Dev Docs</v-btn>
              </li>
            </ul>
          </li>
          <!-- <li class="m-gnb__title">
            <v-btn @click="closeMenu" href="https://www.pocketbattlesnftwar.com/" target="_blank" class="m-gnb__title--link href">Pocket Battles: NFT War</v-btn>
          </li>
          <li class="m-gnb__title">
            <v-btn @click="openSnackBar" class="m-gnb__title--link href">Heroes NFT War</v-btn>
          </li> -->
          <li class="m-gnb__title">
            <p @click="openConvertModal" class="buy-mev">Convert ZP</p>
          </li>
        </ul>
        <div class="m-footer">
          <connect-wallet />
          <div class="m-footer__sns">
            <v-btn target="_blank" :href="item" v-for="(item, index) in snsArray" :key="index" :class="[index]"></v-btn>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import config from '@/config';
import ConnectWallet from "../common/ConnectWallet";
import ConnectWalletMobile from "../common/ConnectWalletMobile";
import { getloggedInData } from '@/utils/cookie';
export default {
  name: 'AppBarView',
  data() {
    return {
      gameTabs: false,
      docsTabs: false,
      gameMobileTabs: true,
      docsMobileTabs: false,
      menu: false,
      snsArray: {
        telegram: 'https://t.me/MEVerse_EN',
        twitter: 'https://twitter.com/MEVerseOfficial',
        medium: 'https://medium.com/meverse',
        linkedin: 'https://www.linkedin.com/company/meverse',
        kakao: 'https://open.kakao.com/o/gDDjvgN',
        facebook: 'https://www.facebook.com/OfficialMEVerse',
      },
    }
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    isAllowChain() {
      if (window.ethereum && window.ethereum?.chainId) {
        const chainId = window.ethereum.chainId;
        if (config.allowChain.includes(chainId)) {
          return true;
        }
      }
      return this.$store.getters.isAllowChain;
    },
  },
   watch: {
    $route(to, from) {
      const userInfo = getloggedInData('expiryGamez')
      if (to.path != from.path) {
        if (userInfo == null) {
          this.$store.dispatch('logout')
        }
      }
    },
   },
  components: {
    ConnectWallet,
    ConnectWalletMobile
  },
  mounted() {
  },
  methods: {
    openSnackBar() {
      const data = 'Coming Soon'
      this.$store.dispatch('openErrorSnack', { data });
      this.closeMenu()
    },
    goLink() {
      window.open('https://meversedex.io/swap', '_blank');
      if (this.menu) return this.menu = false;
    },
    openGameTab() {
      this.gameTabs = true;
    },
    closeGameTab() {
      this.gameTabs = false;
    },
    openDocsTab() {
      this.docsTabs = true;
    },
    closeDocsTab() {
      this.docsTabs = false;
    },
    activeGameTab() {
      this.gameMobileTabs = !this.gameMobileTabs;
    },
    activeDoceTab() {
      this.docsMobileTabs = !this.docsMobileTabs;
    },
    openMenu() {
      this.menu = true;
      document.querySelector('html').style.overflow = 'hidden';
    },
    closeMenu() {
      this.menu = false;
      document.querySelector('html').style.overflow = 'visible';
    },
    openConvertModal() {
      if (!this.walletAddress) return this.$store.dispatch('openSelectWalletModal');
      this.$store.dispatch('openConvertModal');
    },
  }
}
</script>

<style>

</style>