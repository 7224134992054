<template>
  <div class="casc-wrap">
    <div class="casc-wrap__header rank">
      <div class="casc-wrap__header--inner view">
        <div class="header-info">
          <div class="header-info__title">
            <p>Rankings</p>
            <div class="title-tag top-100">
              <span>TOP 100</span>
            </div>
          </div>
          <div class="header-info__desc">
            <p>Who is the best player in MEVerse GameZ?</p>
            <p>Play our fun and easy games and become the best of the best!</p>
          </div>
        </div>
      </div>
    </div>
    <div class="casc-wrap__body">
      <div class="view section">
        <div class="section__header">
          <div class="section__header--tool">
            <div class="tabs">
              <ul class="tabs__items">
                <li @click="changeTabs(tab)"
                v-for="(tab, index) in tabs"
                :key="index"
                :class="{ 'isActive': state == tab }">{{ tab }}</li>
              </ul>
            </div>
            <div class="filter" :class="{ 'isDrop': filterDrop }">
              <div @click="dropFilter" class="filter__state">
                <p v-if="filterGroup[0]">{{ filterState.game_name }}</p>
              </div>
              <ul v-if="filterDrop" class="filter__group" v-click-outside="closeFilter">
                <li @click="changeFilter(item)" v-for="(item, index) in filterGroup" :key="index">
                  <p :class="{ 'isActive': item.game_name == filterState.game_name }">{{ item.game_name }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="list.length > 0" class="table-grid">
          <ul class="table-grid__header table-grid__inner">
            <li><p>Username(address)</p></li>
            <li><p>Play Counts</p></li>
            <li><p>EST. Reward</p></li>
          </ul>
          <rank-row-item :load="load" :loading="loading" v-for="(item, index) in list" :key="index" :item="item"></rank-row-item>
        </div>
        <no-data-info v-else></no-data-info>
        <div v-if="list.length < count" class="btn-wrap">
          <v-btn @click="moreList" class="rank-more">Load More</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import makeBlockie from 'ethereum-blockies-base64';
import { getGameListAPI, getRankingListAPI } from '@/api/server/game/index.js';
import RankRowItem from '@/components/items/RankRowItem.vue';
import NoDataInfo from '@/components/items/NoDataInfo.vue';
export default {
  name: 'Ranking',
  data() {
    return {
      filterDrop: false,
      filterState: {},
      filterGroup: [],
      tabs: [
        // 'Social Casual',
        'Hyper Casual'
      ],
      state: 'Hyper Casual',
      list: [],
      load: true,
      loading: true,
      page: 1,
      count: '',
    }
  },
  components: {
    RankRowItem,
    NoDataInfo
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    playerId() {
      return this.$store.state.user.userInfo.playerId;
    },
  },
  watch: {
    // '$route' (to, from) {
    //   if (to.fullPath != from.fullPath) {
    //     console.log('test');
    //     this.init()
    //     this.$router.push(`/rank?t=${this.state}&f=${JSON.stringify(this.filterState)}`).catch(()=>{});
    //   }
    // }
  },
  mounted() {
    this.init()
  },
  methods: {
    makeBlockie,
    init() {
      if (this.$route.query.t) {
        this.state = this.$route.query.t;
      }
      if (this.$route.query.f) {
        this.filterState = JSON.parse(this.$route.query.f);
      }
      this.getList()
    },
    setFilterGroup(list) {
      const group = [
        {
          game_id: '',
          game_name: 'All'
        }
      ]
      for (const item of list) {
        group.push({game_name: item.game_name, game_id: item.game_id})
      }
      this.filterGroup = group;
    },
    getList() {
      if (!Object.values(this.filterState).length) {
        this.filterState = {
          game_id: '',
          game_name: 'All'
        };
      }
      this.page = 1;
      this.list = [];
      const providerId = this.providerType(this.state);
      const playerId = this.playerId ? this.playerId : null;
      const pageNo = 1; // TODO: game list page no
      const gameId = this.filterState.game_id;
      getGameListAPI(providerId, playerId, '', pageNo, '')
      .then(res => {
        const filterData = res?.data;

        this.setFilterGroup(filterData)
        this.getRankingList(providerId, this.page, gameId)
      })
      .catch(e => {
        console.log('e: ', e);
      })
    },
    getRankingList(providerId, page, gameId) {
      getRankingListAPI(providerId, page, gameId)
      .then(res => {
        // console.log(res);
        this.list = res?.data;
        this.count = Number(res?.count);

        setTimeout(() => {
          if (this.load) this.load = false;
          this.loading = false;
        }, 1000);
      })
      .catch(e => {
        console.log('e: ', e);
      })
    },
    moreList() {
      if (this.list.length == this.count) {
        return;
      }
      this.page += 1;
      const providerId = this.providerType(this.state);
      const gameId = this.filterState.game_id;
      getRankingListAPI(providerId, this.page, gameId)
      .then(res => {
        this.list.push(...res?.data);
        this.count = Number(res?.count);
      })
      .catch(e => {
        console.log('e: ', e);
      })
    },
    changeTabs(type) {
      if (this.state == type) return;
      this.state = type;
      this.filterState = this.filterGroup[0];
      this.getList()
      // this.$router.replace(`/rank?t=${this.state}&f=${JSON.stringify(this.filterState)}`).catch(()=>{});
    },
    dropFilter() {
      this.filterDrop = !this.filterDrop;
    },
    closeFilter() {
      this.filterDrop = false;
    },
    changeFilter(type) {
      this.filterState = type;
      this.load = true;
      this.loading = true;
      this.closeFilter()
      this.getList()
      this.$router.replace(`/rank?t=${this.state}&f=${JSON.stringify(this.filterState)}`).catch(()=>{});
    },
  },
}
</script>

<style>

</style>