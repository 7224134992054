import types from "@/store/components/types";

const {
  PUSH_COMPONENT,
  OPEN_WALLET,
  CLOSE_WALLET,
  OPEN_SELECT_WALLET,
  CLOSE_SELECT_WALLET,
  OPEN_CONVERT,
  CLOSE_CONVERT,
  OPEN_TRANSFER,
  CLOSE_TRANSFER,
  OPEN_NETWORKS,
  CLOSE_NETWORKS,
  OPEN_GAME_PLAY,
  CLOSE_GAME_PLAY,
  OPEN_PLAYING_POPUP,
  CLOSE_PLAYING_POPUP,
  OPEN_ERROR_SNACK,
  CLOSE_ERROR_SNACK,
  OPEN_SUCCESS_SNACK,
  CLOSE_SUCCESS_SNACK,
} = types;

const components = {
  state: {
    items: [],
    wallet: {
      init: false,
      state: undefined,
    },
    selectWallet: {
      init: false,
      state: undefined,
    },
    convert: {
      init: false,
      state: undefined,
    },
    transfer: {
      init: false,
      state: undefined,
    },
    networks: {
      init: false,
      state: undefined,
    },
    playGame: {
      init: false,
      state: undefined,
      data: {},
    },
    playingPopup: {
      init: false,
      state: undefined,
      url: '',
    },
    errorSnack: {
      init: false,
      state: undefined,
      data: '',
    },
    successSnack: {
      init: false,
      state: undefined,
      data: '',
    },
  },
  getters: {
    getWalletInit: (state) => state.wallet.init,
    getSelectWalletInit: (state) => state.selectWallet.init,
    getConvertInit: (state) => state.convert.init,
    getTransferInit: (state) => state.transfer.init,
    getNetworksInit: (state) => state.networks.init,
    getPlayGameInit: (state) => state.playGame.init,
    getPlayingPopupInit: (state) => state.playingPopup.init,
    getErrorSnackInit: (state) => state.errorSnack.init,
    getSuccessSnackInit: (state) => state.successSnack.init,
  },
  actions: {
    openErrorSnack(context, { data }) {
      const isInit = context.getters.getErrorSnackInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "ErrorSnack" */ "@/components/items/ErrorSnack.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "ErrorSnack" });
      }
      context.commit(OPEN_ERROR_SNACK, { data });
    },
    closeErrorSnack(context) {
      context.commit(CLOSE_ERROR_SNACK);
    },
    openSuccessSnack(context, { data }) {
      const isInit = context.getters.getSuccessSnackInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "SuccessSnack" */ "@/components/items/SuccessSnack.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "SuccessSnack" });
      }
      context.commit(OPEN_SUCCESS_SNACK, { data });
    },
    closeSuccessSnack(context) {
      context.commit(CLOSE_SUCCESS_SNACK);
    },
    openPlayGameModal(context, { data }) {
      const isInit = context.getters.getPlayGameInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "PlayGame" */ "@/components/modal/PlayGame.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "PlayGame" });
      }
      context.commit(OPEN_GAME_PLAY, { data });
    },
    closePlayGameModal(context) {
      context.commit(CLOSE_GAME_PLAY);
    },
    openPlayingPopupModal(context, { url }) {
      const isInit = context.getters.getPlayingPopupInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "PlayingPopup" */ "@/components/modal/PlayingPopup.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "PlayingPopup" });
      }
      context.commit(OPEN_PLAYING_POPUP, { url });
    },
    closePlayingPopupModal(context) {
      context.commit(CLOSE_PLAYING_POPUP);
    },
    openWalletModal(context) {
      const isInit = context.getters.getWalletInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "Wallet" */ "@/components/modal/Wallet.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "Wallet" });
      }
      context.commit(OPEN_WALLET);
    },
    closeWalletModal(context) {
      context.commit(CLOSE_WALLET);
    },
    openSelectWalletModal(context) {
      const isInit = context.getters.getSelectWalletInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "SelectWallet" */ "@/components/modal/SelectWallet.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "SelectWallet" });
      }
      context.commit(OPEN_SELECT_WALLET);
    },
    closeSelectWalletModal(context) {
      context.commit(CLOSE_SELECT_WALLET);
    },
    openConvertModal(context) {
      const isInit = context.getters.getConvertInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "Convert" */ "@/components/modal/Convert.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "Convert" });
      }
      context.commit(OPEN_CONVERT);
    },
    closeConvertModal(context) {
      context.commit(CLOSE_CONVERT);
    },
    openTransferModal(context) {
      const isInit = context.getters.getTransferInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "Transfer" */ "@/components/modal/Transfer.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "Transfer" });
      }
      context.commit(OPEN_TRANSFER);
    },
    closeTransferModal(context) {
      context.commit(CLOSE_TRANSFER);
    },
    openNetworksModal(context) {
      const isInit = context.getters.getNetworksInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "Networks" */ "@/components/modal/Networks.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "Networks" });
      }
      context.commit(OPEN_NETWORKS);
    },
    closeNetworksModal(context) {
      context.commit(CLOSE_NETWORKS);
    },
  },
  mutations: {
    [PUSH_COMPONENT](state, { component, id }) {
      state.items.push({ component, id });
    },
    [OPEN_WALLET](state) {
      state.wallet = {
        init: true,
        state: true,
      };
    },
    [CLOSE_WALLET](state) {
      state.wallet.state = false;
    },
    [OPEN_SELECT_WALLET](state) {
      state.selectWallet = {
        init: true,
        state: true,
      };
    },
    [CLOSE_SELECT_WALLET](state) {
      state.selectWallet.state = false;
    },
    [OPEN_CONVERT](state) {
      state.convert = {
        init: true,
        state: true,
      };
    },
    [CLOSE_CONVERT](state) {
      state.convert.state = false;
    },
    [OPEN_TRANSFER](state) {
      state.transfer = {
        init: true,
        state: true,
      };
    },
    [CLOSE_TRANSFER](state) {
      state.transfer.state = false;
    },
    [OPEN_NETWORKS](state) {
      state.networks = {
        init: true,
        state: true,
      };
    },
    [CLOSE_NETWORKS](state) {
      state.networks.state = false;
    },
    [OPEN_ERROR_SNACK](state, { data }) {
      state.errorSnack = {
        init: true,
        state: true,
        data
      };
    },
    [CLOSE_ERROR_SNACK](state) {
      state.errorSnack.state = false;
      state.errorSnack.data = {};
    },
    [OPEN_SUCCESS_SNACK](state, { data }) {
      state.successSnack = {
        init: true,
        state: true,
        data
      };
    },
    [CLOSE_SUCCESS_SNACK](state) {
      state.successSnack.state = false;
      state.successSnack.data = {};
    },
    [OPEN_GAME_PLAY](state, { data }) {
      state.playGame = {
        init: true,
        state: true,
        data
      };
    },
    [CLOSE_GAME_PLAY](state) {
      state.playGame.state = false;
      state.playGame.data = {};
    },
    [OPEN_PLAYING_POPUP](state, { url }) {
      state.playingPopup = {
        init: true,
        state: true,
        url
      };
    },
    [CLOSE_PLAYING_POPUP](state) {
      state.playingPopup.state = false;
      state.playingPopup.url = '';
    },
  },
};
export default components;
