import config from '@/config';
import store from '@/store';
import define from '@/define';
import { request, callTransactionPromise, callTransaction } from '@/utils/promise.js';
import {
  web3,
  injectContract,
  injectContractWithType,
  injectContractWithTypeAndAddress,
} from '@/libs/injectionManager';

const Type = {
  TOKEN: 'token',
  CONVERTER: 'converter',
}
const MAX_SUPPLY = '0xffffffffffffffffffffffffffffffff';

const METHODS = {
  GET_BALANCE_OF: 'balanceOf',
  GET_ALLOWANCE: 'allowance',
  APPROVE: 'approve',
  CONVERTER_ZP: 'convertZP',
  REQUEST_CONVERTER_MEV: 'requestConvertMEV',
}

const callInstance = (instance, method, params) => {
  const walletType = store.state.chain.chain.type ?? define.FACE_WALLET;
  if (walletType == define.FACE_WALLET) {
    return instance[method](...params);
  } else {
    return instance.methods[method](...params).call();
  }
}

const sendInstance = async (instance, method, params) => {
  const walletType = store.state.chain.chain.type ?? define.FACE_WALLET;
  if (walletType == define.FACE_WALLET) {
    return instance[method](...params);
  } else {
    const toSendWei = '0x00';
    const methods = instance.methods[method](...params)
    const sendData = await getSendData(toSendWei, methods);
    return methods.send(sendData)
  }
}

const getSendData = async (toSendWei, methods) => {
  const walletAddress = store.state.user.userInfo.walletAddress
  const [ GAS, GAS_PRICE ] = await Promise.all([
    methods.estimateGas({
      from: walletAddress
    }),
    web3.eth.getGasPrice()
  ])
  const sendData = {};
  sendData.gas = GAS
  sendData.gasPrice = GAS_PRICE;
  sendData.value = toSendWei;
  sendData.from = walletAddress;
  return sendData;
};
const MEVERSE_NUMBER = 0;
const TOKENS_ADDRESS = config.contract[MEVERSE_NUMBER].token;

class web3Manager {
  getBalanceOf = (token, userAddress) => {
    return new Promise((resolve, reject) => {
      const tokenAddress = TOKENS_ADDRESS[token];
      const tokenInstance = injectContractWithTypeAndAddress(Type.TOKEN, tokenAddress);
      tokenInstance.methods[METHODS.GET_BALANCE_OF](userAddress)
      .call()
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
    });
  }

  getAllowance = (token, userAddress, router) => {
    return new Promise((resolve, reject) => {
      const owner = userAddress;
      const spender = router;
      const tokenAddress = TOKENS_ADDRESS[token];
      const tokenInstance = injectContractWithTypeAndAddress(Type.TOKEN, tokenAddress);
      const params = [ owner, spender ]
      callInstance(tokenInstance, METHODS.GET_ALLOWANCE, params)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
    });
  }

  approve = (token, spender) => {
    return new Promise((resolve, reject) => {
      const tokenAddress = TOKENS_ADDRESS[token];
      const tokenInstance = injectContractWithTypeAndAddress(Type.TOKEN, tokenAddress);
      const params = [ spender, MAX_SUPPLY ];
      sendInstance(tokenInstance, METHODS.APPROVE, params)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
    })
  }

  convertZP = (mevAmt) => {
    return new Promise((resolve, reject) => {
      const instance = injectContractWithType(Type.CONVERTER);
      const params = [ mevAmt ];
      sendInstance(instance, METHODS.CONVERTER_ZP, params)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
    });
  }

  requestConvertMEV = (ZPAmt) => {
    return new Promise((resolve, reject) => {
      const instance = injectContractWithType(Type.CONVERTER);
      const params = [ ZPAmt ];
      sendInstance(instance, METHODS.REQUEST_CONVERTER_MEV, params)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      });
    });
  }
}

export const manager = new web3Manager();