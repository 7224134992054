import axios from '@/utils/axios';
// import { getCookie } from '@/utils/cookie';
import define from '@/define';
const url = define.URL;

/**
 * 게임 리스트 조회
 * @param {Number} provider_id 게임 제공 업체 ID
 * @param {Number} player_id 게임 유저 ID
 * @param {String} search_text 검색어
 * @param {Number} page_no 페이지 넘버
 * @param {String} order_by 정렬 기준
 */
export const getGameListAPI = async (provider_id, player_id, search_text, page_no, order_by) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/game/list?provider_id=${provider_id}&player_id=${player_id}&search_text=${search_text}&page_no=${page_no}&order_by=${order_by}`;
    axios.get(requestUrl)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * 게임 랭킹 리스트 조회
 * @param {Number} provider_id 게임 제공 업체 ID
 * @param {Number} page_no 페이지 넘버
 * @param {Number} game_id 게임 ID
 */
export const getRankingListAPI = async (provider_id, page_no, game_id) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/game/rankingList?provider_id=${provider_id}&page_no=${page_no}&game_id=${game_id}`;
    axios.get(requestUrl)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * 게임 목록 좋아요
 * @param {Number} game_id 게임 ID
 * @param {Number} player_id 게임 유저 ID
 */
export const setLikeAPI = async (game_id, player_id) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/game/setLike`;
    const body = { game_id, player_id };
    axios.post(requestUrl, body)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * 게임 목록 좋아요 취소
 * @param {Number} game_id 게임 아이디
 * @param {Number} player_id 게임 유저 ID
 */
export const setUnlikeAPI = async (game_id, player_id) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/game/setUnlike`;
    const body = { game_id, player_id };
    axios.post(requestUrl, body)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * 게임 플레이 카운트 증가
 * @param {Number} game_id 게임 ID
 */
export const addGamePlayCountsUpAPI = async (game_id) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/game/increasePlayCount?game_id=${game_id}`;
    axios.post(requestUrl)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

export const getBannerGameListAPI = async () => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/game/bannerGameList`;
    axios.get(requestUrl)
    .then(res => {
      resolve(res.data);
    })
    .catch(e => {
      reject(e);
    })
  })
}