import axios from '@/utils/axios';
import define from '@/define';
const url = define.URL;

/**
 * 등록된 유저 정보 조회
 * @param {String} wallet_address 지갑 주소
 */
export const getUserInfoAPI = async (walletAddress) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/player/${walletAddress}`;
    axios.get(requestUrl)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * 등록된 유저 정보 조회
 * @param {String} wallet_address 지갑 주소
 */
export const getUserPlayCountsAPI = async (walletAddress, gameId) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/player/getInfo/${walletAddress}/${gameId}`;
    axios.get(requestUrl)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * 유저 정보 수정 등록
 * @param {String} wallet_address 지갑 주소
 * @param {Number} player_id 게임유저 아이디
 * @param {String} nic_name 닉네임
 * @param {Number} balance 지갑 잔액
 * @param {Boolean} is_used 사용유무
 * @param {String} update_date 수정일
 * @param {String} last_login_date 마지막 로그인 일자
 */
export const updateUserInfoAPI = async (player_id, wallet_address, nic_name, balance, is_used, update_date, last_login_date) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/player/update/${player_id}`;
    const body = { wallet_address, nic_name, balance, is_used, update_date, last_login_date };
    axios.patch(requestUrl, body)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * 게임유저 닉네임 중복 체크 조회
 * @param {String} nic_name 닉네임
 */
export const getCheckNicNameAPI = async (nic_name) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/player/checkNicname/${nic_name}`;
    axios.get(requestUrl)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * 유저의 플레이 히스토리 (wallet modal)
 * @param {Number} player_id 게임유저 아이디
 */
export const getPlayHistoryAPI = async (player_id) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/player/playHistory/${player_id}`;
    axios.get(requestUrl)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * 유저가 좋아요한 게임 리스트 (wallet modal)
 * @param {Number} player_id 게임유저 아이디
 */
export const getMyGamesAPI = async (player_id) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/player/myGame/${player_id}`;
    axios.get(requestUrl)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * 유저의 플레이 히스토리 (profile mygames page)
 * @param {Number} player_id 게임유저 아이디
 */
export const getMyGamePlayHistoryAPI = async (player_id) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/player/myGame/rank/${player_id}`;
    axios.get(requestUrl)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * discord info 생성등록
 */
export const addDiscordInfoAPI = async (wallet_address, discord_id, discord_username, discriminator, discord_locale) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/player/discord`;
    const body = { wallet_address, discord_id, discord_username, discriminator, discord_locale };
    axios.post(requestUrl, body)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * discord info 삭제
 */
export const removedDiscordInfoAPI = async (wallet_address, discord_id) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/player/removeDiscord`;
    const body = { wallet_address, discord_id };
    axios.post(requestUrl, body)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * discord info 조회
 * @param {String} wallet_address 지갑 주소
 */
export const getDiscordInfoAPI = async (id) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/player/discord/${id}`;
    axios.get(requestUrl)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * MEV -> ZP converter
 */
export const addConverterZpAPI = async (transaction_id, player_id, mev_amount, zp_amount) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/player/convertZP`;
    const body = { transaction_id, player_id, mev_amount, zp_amount };
    axios.post(requestUrl, body)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * ZP -> MEV requestConver
 */
export const addRequestConvertMevAPI = async (transaction_id, player_id, mev_amount, zp_amount) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/player/requestConvertMEV`;
    const body = { transaction_id, player_id, mev_amount, zp_amount };
    axios.post(requestUrl, body)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * request Count
 * @param {Number} player_id 게임유저 아이디
 */
export const getrequestCountAPI = async (player_id) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/player/requestCount/${player_id}`;
    axios.get(requestUrl)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}