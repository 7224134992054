<template>
  <div class="home">
    <div class="banner">
      <main-slide></main-slide>
    </div>
    <div class="view section">
      <div class="section__header">
        <p class="section__header--title">Trending GameZ</p>
        <div class="tabs">
          <ul class="tabs__items">
            <li @click="changeTabs(tab, 'game')"
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{ 'isActive': gametabState == tab }">{{ tab }}</li>
          </ul>
          <v-btn class="more-btn m-none"
          :to="gametabState == 'Hyper Casual' ? '/hyper-gamez' : '/social-gamez'">View More</v-btn>
        </div>
      </div>
      <div v-if="gameList.length > 0" class="game-grid">
        <game-grid-play-item :load="game_load" :loading="game_loading" v-for="(item, index) in gameList" :key="index" :item="item" @reload="getGameList"></game-grid-play-item>
      </div>
      <no-data-info v-else></no-data-info>

    </div>
    <div class="sub-banner">
      <div class="sub-banner__inner">
        <div class="desc-wrap">
          <p>Get MEV to play various games!</p>
          <span>You don't have MEV coin yet? MEV is the main utility coin in MEVerse GameZ. <br />Go to MEVerse Dex and get MEV to start playing games!</span>
        </div>
        <v-btn class="move-btn" href="https://meversedex.io/swap" target="_blank">Buy MEV</v-btn>
      </div>
    </div>

    <div class="view nft">
      <div class="section pocket">
        <div class="section__header">
          <p class="section__header--title">Pocket Battles: NFT War</p>
        </div>
        <div class="section__body">
          <img src="@/assets/media/Content/nft/PocketBattles_Logo.png" alt="Pocket Battles NFT Logo" />
          <div class="section__body--desc">
            <p>Pocket Battles: NFT War is the world's best casual strategy battle P2E game</p>
            <v-btn href="https://www.pocketbattlesnftwar.com/" target="_blank">Visit Website</v-btn>
          </div>
        </div>
      </div>
      <div class="section heroes">
        <div class="section__header">
          <p class="section__header--title">X Heroes: NFT War</p>
        </div>
        <div class="section__body">
          <img src="@/assets/media/Content/nft/Heroes_Logo.png" alt="Heroes NFT Logo" />
          <div class="section__body--desc">
            <p>A collective turn-based strategy RPG game that has over 240 types of Heroes</p>
            <v-btn href="https://www.xheroesnftwar.com/" target="_blank">Visit Website</v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="view section">
      <div class="section__header">
        <p class="section__header--title">Rankings</p>
        <div class="section__header--tool">
          <div class="tabs">
            <ul class="tabs__items">
              <li @click="changeTabs(tab, 'rank')"
              v-for="(tab, index) in tabs"
              :key="index"
              :class="{ 'isActive': ranktabState == tab }">{{ tab }}</li>
            </ul>
          </div>
          <div class="filter" :class="{ 'isDrop': filterDrop }">
            <div @click="dropFilter" class="filter__state">
              <p v-if="filterGroup[0]">{{ filterState.game_name }}</p>
            </div>
            <ul v-if="filterDrop" class="filter__group" v-click-outside="closeFilter">
              <li @click="changeFilter(item)" v-for="(item, index) in filterGroup" :key="index">
                <p :class="{ 'isActive': item.game_name == filterState.game_name }">{{ item.game_name }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="rankList.length > 0" class="table-grid">
        <ul class="table-grid__header table-grid__inner">
          <li><p>Username(address)</p></li>
          <li><p>Play Counts</p></li>
          <li><p>EST. Reward</p></li>
        </ul>
        <rank-row-item :load="rank_load" :loading="rank_loading" v-for="(item, index) in rankList" :key="index" :item="item"></rank-row-item>
      </div>
      <no-data-info v-else></no-data-info>
    </div> -->

    <div class="view section Medium">
      <div class="section__header">
        <p class="section__header--title">Medium</p>
        <v-btn href="https://medium.com/meverse" target="_blank" class="more-btn">View More</v-btn>
      </div>
      <div class="medium-grid">
        <medium-grid-item :info="item" :load="medium_load" :loading="medium_loading" v-for="(item, index) in mediumList" :key="index"></medium-grid-item>
      </div>
    </div>
  </div>
</template>

<script>
import { getGameListAPI } from '@/api/server/game/index.js';
// import { getGameListAPI, getRankingListAPI } from '@/api/server/game/index.js';
import MainSlide from '@/components/content/MainSlide.vue';
import GameGridPlayItem from '@/components/items/GameGridPlayItem.vue';
// import RankRowItem from '@/components/items/RankRowItem.vue';
import MediumGridItem from '@/components/items/MediumGridItem.vue';
import NoDataInfo from '@/components/items/NoDataInfo.vue';
export default {
  name: 'Home',
  data() {
    return {
      filterDrop: false,
      filterState: {},
      filterGroup: [],
      tabs: [
        'Social Casual',
        'Hyper Casual'
      ],
      gametabState: 'Social Casual',
      ranktabState: 'Social Casual',
      gameList: [],
      // rankList: [],
      mediumList: [],
      game_load: true,
      game_loading: true,
      rank_load: true,
      rank_loading: true,
      medium_load: true,
      medium_loading: true,
    }
  },
  components: {
    MainSlide,
    GameGridPlayItem,
    MediumGridItem,
    // RankRowItem,
    NoDataInfo
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    playerId() {
      return this.$store.state.user.userInfo.playerId;
    },
  },
  watch: {
    walletAddress() {
      this.getGameList()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getMedium()
      this.getGameList()
      this.getRankingList()
    },
    async getGameList() {
      const providerId = this.providerType(this.gametabState);
      const playerId = this.playerId ? this.playerId : null;
      const pageNo = 1;
      await getGameListAPI(providerId, playerId, '', pageNo, '')
      .then(res => {
        this.gameList = res?.data;

        setTimeout(() => {
          if (this.game_load) this.game_load = false;
          this.game_loading = false;
        }, 1000);
      })
      .catch(e => {
        console.log('e: ', e);
      })
    },
    setFilterGroup(list) {
      const group = [
        {
          game_id: '',
          game_name: 'All'
        }
      ]
      for (const item of list) {
        group.push({game_name: item.game_name, game_id: item.game_id})
      }
      this.filterGroup = group;
    },
    getRankingList() {
      if (!Object.values(this.filterState).length) {
        this.filterState = {
          game_id: '',
          game_name: 'All'
        };
      }
      const providerId = this.providerType(this.ranktabState);
      const playerId = this.playerId ? this.playerId : null;
      const pageNo = 1;
      // const gameId = this.filterState.game_id;
      getGameListAPI(providerId, playerId, '', pageNo, '')
      .then(async res => {
        const filterData = res?.data;

        this.setFilterGroup(filterData)
        // getRankingListAPI(providerId, pageNo, gameId)
        // .then(res => {
        //   this.rankList = res?.data;

        //   setTimeout(() => {
        //     if (this.rank_load) this.rank_load = false;
        //     this.rank_loading = false;
        //   }, 1000);
        // })
        // .catch(e => {
        //   console.log('e: ', e);
        // })
      })
      .catch(e => {
        console.log('e: ', e);
      })
    },
    changeTabs(type, content) {
      if (content == 'game') {
        this.gametabState = type;
        this.getGameList();
      } else if (content == 'rank') {
        this.ranktabState = type;
        this.filterState = this.filterGroup[0];
        this.getRankingList();
      }
    },
    dropFilter() {
      this.filterDrop = !this.filterDrop;
    },
    closeFilter() {
      this.filterDrop = false;
    },
    changeFilter(type) {
      this.filterState = type;
      this.load = true;
      this.loading = true;
      this.closeFilter()
      this.getRankingList()
    },
    async getMedium() {
      let datas = [];

      const name = "meverseofficial";
      const RSSUrl = `https://medium.com/feed/@${name}`;
      const RSSConverter = `https://api.rss2json.com/v1/api.json?rss_url=${RSSUrl}`;

      const response = await fetch(RSSConverter);
      const data = await response.json();

      for(let item of data.items) {
        datas.push({
          "title": item.title,
          "link": item.link,
          "image": item.thumbnail,
          "date": this.dateParse(item.pubDate) })
      }

      this.mediumList = datas.slice(0, 3);
      setTimeout(() => {
        if (this.medium_load) this.medium_load = false
        this.medium_loading = false;
      }, 1000);
    },
  }
}
</script>
