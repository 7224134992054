<template>
  <div>
    <v-sheet
      v-if="load"
      :loading="loading"
      class="test mx-auto"
    >
      <v-skeleton-loader
        class="mx-auto"
        type="list-item-avatar, list-item, list-item"
      ></v-skeleton-loader>
    </v-sheet>
    <ul v-else class="table-grid__body table-grid__inner">
      <li class="user">
        <span>{{ item.game_rank }}</span>
        <v-img class="userIcon" :src="makeBlockie(item.wallet_address)"></v-img>
        <div class="user__info">
          <p class="nickname">{{ item.nic_name }}</p>
          <updown-item :num="item.up_down"></updown-item>
        </div>
      </li>
      <li><p>{{ insert_comma(item.count) }}</p></li>
      <li><p>{{ toNumberFormat(item.amount) }} ZP</p></li>
      <li class="mobile-table">
        <div class="mobile-table__inner">
          <span>Reward</span>
          <p>{{ Number(item.amount) >= 1000 ? nFormatter(item.amount) : toNumberFormat(item.amount) }} ZP</p>
        </div>
        <div class="mobile-table__inner">
          <span>Play Counts</span>
          <p>{{ insert_comma(item.count) }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import makeBlockie from 'ethereum-blockies-base64';
import UpdownItem from '@/components/items/UpdownItem.vue'
export default {
  name: 'RankRowItem',
  data() {
    return {

    }
  },
  components: {
    UpdownItem
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    load: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    makeBlockie,
  }
}
</script>

<style>

</style>