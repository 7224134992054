import types from './types';
import { getloggedInData } from '@/utils/cookie';
import { getUserInfoAPI } from '@/api/server/player/index.js';
import { manager } from '@/libs/web3Manager';
import web3Utils from 'web3-utils';
import define from '@/define';
import config from '@/config';
import { getBalance } from '@/utils/facewalletLogin';

const {
  SET_USER_INFO,
  REMOVE_USER_INFO,
  CHECK_USER_INFO,
  LOGIN,
  LOGOUT,
} = types;

const tokenSymbol = 'MEV'

const userModule = {
  state: {
    userInfo: {
      walletAddress: '',
      playerId: null,
    },
    userBalance: '',
    userPoint: '',
    allowance: '',
  },
  getters: {
      userInfo: state => state.userInfo,
      userBalance: state => state.userBalance,
      userPoint: state => state.userPoint,
      allowance: state => state.allowance,
  },
  mutations: {
    [SET_USER_INFO](state, userInfo) {
      state.userInfo = userInfo;
      const now = new Date()
      const item = {
        value: userInfo,
        expiry: now.getTime() + 60*60*1000,
      }
      localStorage.setItem('expiryGamez', JSON.stringify(item));
    },
    [REMOVE_USER_INFO](state) {
      state.userInfo =  {
        walletAddress: '',
        playerId: null,
      },
      state.userBalance = '';
      state.userPoint = '';
      state.allowance = '';
      localStorage.removeItem('expiryGamez');
    },
    userBalance(state, balance) {
      state.userBalance = balance;
    },
    userPoint(state, point) {
      state.userPoint = point;
    },
    allowance(state, allowance) {
      state.allowance = allowance;
    },
  },
  actions: {
    [LOGIN]({ commit }, { userInfo }) {
      commit(SET_USER_INFO, userInfo);
    },
    [LOGOUT]({ commit }) {
      commit(REMOVE_USER_INFO)
    },
    [CHECK_USER_INFO]({ commit }) {
      const userInfo = getloggedInData('expiryGamez');
      if (userInfo) {
        commit(SET_USER_INFO, userInfo);
      } else {
        commit(REMOVE_USER_INFO)
      }
    },
    async getBalanceOf({ commit, rootState }, address) { // MEV
      if (!address) return;
      const walletType = rootState.chain.chain.type ?? define.FACE_WALLET;
      if (walletType == 'faceWallet') {
        const balance = await getBalance();
        commit('userBalance', balance)
      } else {
        await manager.getBalanceOf(tokenSymbol, address)
        .then(res => {
          const balance = web3Utils.fromWei(res, 'ether');
          commit('userBalance', balance);
        })
        .catch(e => {
          console.log('e: ', e);
        })
      }
    },
    async getUserPoint({ commit }, address) { // ZP
      if (!address) return;
      await getUserInfoAPI(address)
      .then(res => {
        const info = res?.data;
        const point = info.balance
        commit('userPoint', point);
      })
      .catch(e => {
        console.log('e: ', e);
      })
    },
    async getAllowence({ commit }, address) {
      if (!address) return;
      const converterAddress = config.contract[define.MEVERSE_NUMBER].converter.address;
      const token = 'MEV';
      manager.getAllowance(token, address, converterAddress)
      .then(res => {
        let result = '';
        if (typeof res == 'object') {
          result = web3Utils.fromWei(res._hex, 'ether');
        } else {
          result =  web3Utils.fromWei(res, 'ether');
        }
        commit('allowance', result);
      })
      .catch(e => {
        console.log('e: ', e);
      })
    },
  },
}

export default userModule;