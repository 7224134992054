<template>
  <div v-if="num" class="upDown">
    <p class="upDown__new" v-if="num == 'new'">NEW</p>
    <p class="upDown__number" v-else-if="num != '0'" :class="[ num > 0 ? 'up' : 'down' ]">{{ Math.abs(num) }}</p>
  </div>
</template>

<script>
export default {
  name: 'UpDownItem',
  props: [ 'num' ],
}
</script>

<style>

</style>