export default {
  PUSH_COMPONENT: "PUSH_COMPONENT",
  OPEN_WALLET: "OPEN_WALLET",
  CLOSE_WALLET: "CLOSE_WALLET",
  OPEN_SELECT_WALLET: "OPEN_SELECT_WALLET",
  CLOSE_SELECT_WALLET: "CLOSE_SELECT_WALLET",
  OPEN_CONVERT: "OPEN_CONVERT",
  CLOSE_CONVERT: "CLOSE_CONVERT",
  OPEN_TRANSFER: "OPEN_TRANSFER",
  CLOSE_TRANSFER: "CLOSE_TRANSFER",
  OPEN_NETWORKS: "OPEN_NETWORKS",
  CLOSE_NETWORKS: "CLOSE_NETWORKS",
  OPEN_ERROR_SNACK: "OPEN_ERROR_SNACK",
  CLOSE_ERROR_SNACK: "CLOSE_ERROR_SNACK",
  OPEN_SUCCESS_SNACK: "OPEN_SUCCESS_SNACK",
  CLOSE_SUCCESS_SNACK: "CLOSE_SUCCESS_SNACK",
  OPEN_GAME_PLAY: "OPEN_GAME_PLAY",
  CLOSE_GAME_PLAY: "CLOSE_GAME_PLAY",
  OPEN_PLAYING_POPUP: "OPEN_PLAYING_POPUP",
  CLOSE_PLAYING_POPUP: "CLOSE_PLAYING_POPUP",
};
