import axios from '@/utils/axios';
import define from '@/define';
const url = define.URL;

/**
 * authToken 생성등록
 * @param {Number} player_id 게임유저 아이디
 * @param {Number} provider_id 게임공급 업체 아이디
 * @param {String} wallet_address 지갑 주소
 * @param {Number} game_id 게임 아이디
 */
export const addAuthTokenAPI = async (player_id, provider_id, wallet_address, game_id) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/auth/authToken`;
    const body = { player_id, provider_id, wallet_address, game_id };
    axios.post(requestUrl, body)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * login 처리 사용자 정보 생성
 * @param {String} wallet_address 지갑 주소
 * @param {String} balanace 지갑 잔액
 */
export const addLoginAPI = async (wallet_address, balanace) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/auth/login`;
    const body = { wallet_address, balanace };
    axios.post(requestUrl, body)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * 국가코드 조회
 */
export const getUserCountryInfoAPI = async () => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/player/getCountryCode`;
    axios.post(requestUrl)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}