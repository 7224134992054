<template>
  <div class="casc-wrap">
    <div class="casc-wrap__header gamez">
      <div class="casc-wrap__header--inner view">
        <div class="header-info">
          <div class="header-info__title">
            <p>Social Casual</p>
          </div>
          <div class="header-info__desc">
            <p>Play MEVerse GameZ with MEV and have a chance to win more! <br class="break" />Click the games to explore what can be earned!</p>
          </div>
        </div>
      </div>
    </div>
    <div class="casc-wrap__body gamez">
      <div class="view section">
        <div class="section__header">
          <div class="section__header--tool">
            <div class="tabs">
              <ul class="tabs__items">
                <li @click="changeTabs(tab)"
                v-for="(tab, index) in tabs"
                :key="index"
                :class="{ 'isActive': tabState == tab }">{{ tab }}</li>
              </ul>
            </div>
            <div class="search-bar">
              <v-btn @click="getList" class="search-bar__btn"></v-btn>
              <v-text-field
                v-model="keyword"
                label="Search"
                @keyup.enter="getList"
                solo
              ></v-text-field>
            </div>
          </div>
        </div>
        <div v-if="list.length > 0" class="game-grid">
          <game-grid-play-item :load="load" :loading="loading" v-for="(item, index) in list" :key="index" :item="item" @reload="getList"></game-grid-play-item>
        </div>
        <no-data-info v-else></no-data-info>
      </div>
    </div>
  </div>
</template>

<script>
import { getGameListAPI } from '@/api/server/game/index.js';
import GameGridPlayItem from '@/components/items/GameGridPlayItem.vue';
import NoDataInfo from '@/components/items/NoDataInfo.vue';
export default {
  name: 'Social',
  data() {
    return {
      tabs: [
        'All',
        'Most Played'
      ],
      tabState: 'All',
      keyword: '',
      providerId: 1,
      list: [],
      load: true,
      loading: true,
    }
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    playerId() {
      return this.$store.state.user.userInfo.playerId;
    },
  },
  watch: {
    walletAddress() {
      this.init()
    }
  },
  components: {
    GameGridPlayItem,
    NoDataInfo
  },
  mounted() {
    this.init()
  },
  methods: {
    init () {
      if (this.$route.query.t) {
        this.tabState = this.$route.query.t;
      }
      if (this.$route.query.k) {
        this.keyword = this.$route.query.k;
      }
      this.getList()
    },
    changeTabs(type) {
      this.tabState = type;
      this.getList();
    },
    OrderType(type) {
      switch (type) {
        case 'All' : return '';
        case 'Most Played' : return 'most';
        default : return '';
      }
    },
    getList() {
      const player_id = this.playerId ? this.playerId : null;
      const search_text = this.keyword ? this.keyword : '';
      const page_no = 1;
      const order_by = this.OrderType(this.tabState);
      getGameListAPI(this.providerId, player_id, search_text, page_no, order_by)
      .then(res => {
        this.list = res?.data;
        this.$router.replace(`/social-gamez?t=${this.tabState}&k=${search_text}`).catch(()=>{});

        setTimeout(() => {
          if (this.load) this.load = false
          this.loading = false;
        }, 1000);
      })
      .catch(e => {
        console.log('e: ', e);
      })
    },
  }
}
</script>

<style>

</style>