<template>
  <div class="connect-bar">
    <div v-if="walletAddress" class="connect-bar__inner">
      <div v-if="!isAllowChain" class="wrong-network">
        <v-btn @click="openNetworksModal">Wrong Network</v-btn>
      </div>
      <div v-else class="userIn">
        <div class="userIn__inner balance">
          <div class="balance-in">
            <img src="@/assets/media/Symbol/ZP.svg" alt="ZP symbol logo">
            <p>{{ userPoint ? toNumberFormat(userPoint) : '0.00' }} ZP</p>
          </div>
          <div class="balance-in MEV">
            <img src="@/assets/media/Symbol/MEV_bg_black.svg" alt="MEV MEVerse symbol logo">
            <p>{{ userBalance ? toNumberFormat(userBalance) : '0.00' }} MEV</p>
          </div>
        </div>
        <div @click="openWalletModal" class="userIn__inner wallet">
          <p>{{ concatAddress(walletAddress, 4, 4) }}</p>
          <v-img :src="makeBlockie(walletAddress)"></v-img>
        </div>
      </div>
    </div>
    <div v-else class="connect">
      <v-btn @click="openSelectWallet">Connect Wallet</v-btn>
      <!-- <v-btn @click="login">Connect Wallet</v-btn> -->
    </div>
  </div>
</template>

<script>
import define from '@/define';
import config from '@/config';
import makeBlockie from 'ethereum-blockies-base64';
import { provider, face } from '@/utils/facewalletLogin';
export default {
  name: "ConnectWallet",
  data() {
    return {
      isFaceChainId: undefined
    }
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    userBalance() {
      return this.$store.state.user.userBalance;
    },
    userPoint() {
      return this.$store.state.user.userPoint;
    },
    chainId() {
      return this.$store.state.chain.chain.id;
    },
    isAllowChain() {
      const walletType = this.$store.state.chain.chain.type ?? define.FACE_WALLET;
      if (walletType == define.METAMASK) {
        if (window.ethereum && window.ethereum?.chainId) {
          const chainId = window.ethereum.chainId;
          if (config.allowChain.includes(chainId)) {
            return true;
          }
        }
      } else if (walletType == define.FACE_WALLET) {
        if (provider !== 'undefined' && this.isFaceChainId) {
          if (config.allowChain.includes(this.isFaceChainId)) {
            return true;
          }
        }
      }
      return this.$store.getters.isAllowChain;
    },
  },
  async mounted() {
    await this.faceChainId();
  },
  methods: {
    makeBlockie,
    openSelectWallet() {
      this.$store.dispatch('openSelectWalletModal');
    },
    openWalletModal() {
      this.$store.dispatch('openWalletModal');
    },
    openNetworksModal() {
      this.$store.dispatch('openNetworksModal');
    },
    async faceChainId() {
      await face.getChainId()
      .then(res => {
        let num = res-0
        num = "0x" + num.toString(16)
        this.isFaceChainId = num;
      })
    },
  }
}
</script>

<style>

</style>